import React from "react"
import DatenschutzAppContent from "../components/DatenschutzAppContent"
import Layout from "../components/Layout"
import Section from "../components/Section"

export interface DatenschutzAppProps {}

const DatenschutzApp: React.SFC<DatenschutzAppProps> = () => {
  return (
    <Layout>
      <Section>
        <DatenschutzAppContent />
      </Section>
    </Layout>
  )
}

export default DatenschutzApp
