import { Link } from "gatsby"
import React from "react"
import { H1 } from "./text/H1"
import { LinkType, OutsideLink } from "./text/OutsideLink"
import { Paragraph } from "./text/Paragraph"

export interface DatenschutzAppContentProps {}

const DatenschutzAppContent: React.SFC<DatenschutzAppContentProps> = () => {
  return (
    <>
      <H1 text="Informationen zu den App-Daten" />
      <Paragraph>
        Wir haben viel Arbeit in „Die Psychotherapie App“ gesteckt, um
        sicherzustellen, dass alle Funktionen der App ohne die Speicherung bzw.
        Verarbeitung von personenbezogenen Daten genutzt werden können.
      </Paragraph>
      <Paragraph>
        <strong>
          In der Web-App „Die Psychotherapie App“ werden keine personenbezogenen
          Daten benötigt, verarbeitet oder gespeichert.
        </strong>
      </Paragraph>
      <Paragraph>
        Selbst Ihre so genannte IP-Adresse, die sonst eigentlich immer komplett
        verfügbar ist und oft auch zu analytischen Zwecken gespeichert wird,
        kommt auf App-Webseite nur anonymisiert an.
      </Paragraph>
      <Paragraph>
        Im Moment setzt die App bei Nutzung einen Cookie. Diesen brauchen wir,
        um die App bzgl. des Handlings zu optimieren. Cookies sind kleine
        Textinformationen, die über ihren Browser auf Ihrem Endgerät gespeichert
        werden. Der Cookie wird nach Schließen der App sofort gelöscht wird. In
        dem Cookie werden aber keine personenbezogene Daten gespeichert.
      </Paragraph>
      <Paragraph>
        Die App verwendet auch keine Webfonts und keine Standortdaten.
      </Paragraph>
      <Paragraph>
        Ein Personenbezug mit den App-Daten oder den technischen Daten
        herzustellen ist nicht möglich.
      </Paragraph>
      <Paragraph>
        Die Nutzer könnten natürlich Daten erfassen, die einen Personenbezug
        zulassen ((z.B. Name oder Telefonnummer). Wir können das nicht
        verhindert. Das ist so, als wenn man Telefonnummern in seinem Handy
        speichert: Die Verantwortung für diese Daten trägt der Nutzer.
      </Paragraph>
      <Paragraph>
        Für die Nutzung der App sind aber keine solche Daten notwendig.
      </Paragraph>
      <br />
      <Paragraph>
        Da keine personenbezogenen Daten verarbeitet werden, entfällt die
        Notwendigkeit nach Nennung des Zwecks, die Nennung der Rechtsgrundlage,
        Empfänger der Daten, Weitergabe und Speicherung in Drittländern. Wir
        verwenden Mamoto als Analysesoftware, aber da die IP-Adresse nur
        anonymisiert vorliegt, ist auch hier kein Personenbezug möglich.
        Natürlich haben Sie alle Rechte, die Ihnen die DSGVO einräumt (Auskunft,
        Speicherung, Löschung, Widerspruch und Übertragung von Daten), aber da
        es keine personenbezogenen Daten gibt, könnten wir z.B. eine Anfrage gar
        nicht bearbeiten.
      </Paragraph>
      <Paragraph>
        Natürlich schützen wir alle Daten, die Sie eingeben, nach dem neuesten
        Stand der Technik und sorgen dafür, dass niemand an die Daten
        herankommt.
      </Paragraph>
      <Paragraph>
        Sollten Sie Grund für eine Beschwerde bzgl. des Datenschutzes sehen,
        können Sie das bei jeder Aufsichtsbehörde in Deutschland tun.
      </Paragraph>
      <Paragraph>
        Sollten Sie generelle Fragen zu dieser Erklärung oder allgemein zum
        Thema Datenschutz bei uns haben, können Sie sich gerne an den
        Verantwortlichen für den Datenschutz, die „DPTA Die Psychotherapie App
        UG (haftungsbeschränkt)“, Kroatengasse 3, 97070 Würzburg,{" "}
        <OutsideLink
          href="kontakt@diepsychotherapieapp.de"
          type={LinkType.mail}
        />
        , Telefon <OutsideLink href="+49 176 43746921" type={LinkType.phone} />{" "}
        oder unseren Datenschutzbeauftragten unter{" "}
        <OutsideLink
          href="datenschutz@diepsychotherapieapp.de"
          type={LinkType.mail}
        />
        , <OutsideLink href="+49 6021 624384" type={LinkType.phone} /> wenden.
      </Paragraph>
      <Paragraph>
        Die allgemeinen Informationen zum Datenschutz in unserer Firma finden
        Sie hier:
      </Paragraph>
      <ul className="list-disc list-inside">
        <li>
          Information zur Datenverarbeitung für die Geschäftstätigkeit der Firma
          „DPTA Die Psychotherapie App UG (haftungsbeschränkt) i. L.“ finden Sie{" "}
          <Link
            className="text-primary-600 hover:text-primary-800 no-underline"
            to="/informationen-datenverarbeitung"
          >
            hier
          </Link>
          .
        </li>
        <li>
          Datenschutzerklärung unserer Firmen-Webseite, zum Newsletter und zu
          Fragebögen finden Sie in unserer allgemeinen{" "}
          <Link
            className="text-primary-600 hover:text-primary-800 no-underline"
            to="/datenschutzerklaerung"
          >
            Datenschutzerklärung
          </Link>
          .
        </li>
      </ul>
    </>
  )
}

export default DatenschutzAppContent
